import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import defaultThemeOptions from '@octanner/prism-core/ThemeProvider/defaultTheme';
import ScopedCssBaseline from '@octanner/prism-core/ScopedCssBaseline';
import {
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import { TranslationContext } from './useTranslation';

const ReactProviders = ({ children, translateKey }) => {
  const [theme, setTheme] = useState(createMuiTheme());

  useEffect(() => {
    const newTheme = createMuiTheme(defaultThemeOptions);
    setTheme(newTheme);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <TranslationContext.Provider value={{ translateKey }}>
          {children}
        </TranslationContext.Provider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

ReactProviders.propTypes = {
  children: PropTypes.element,
  translateKey: PropTypes.func
};

export default ReactProviders;
